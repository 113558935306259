import Vue from 'vue';
import Vuetify from 'vuetify/lib';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#DADCE5'
            }
        },
        icons: {
            defaultSet: 'fa'
        }
    }
});
